<script>
import Item from './components/Item.vue'

export default {
	_config: {"route": {"path": "look1", "meta": {"title": "查看"}}},
	components: {Item},
	data() {
		return {
			data: {}
		}
	},
	created() {
		this.$_axios2.get('api/logistics/student-feedback/show?id=' + this.$route.query.id).then(res => {
			this.data = res.data.data
			console.log(this.data, '----------------')
		})
	}
}
</script>

<template>
	<div style="background: #F7F7F7">
		<div style="width: calc(100% - 24rem);min-height: 460rem;background: #FFFFFF;border-radius: 8rem 8rem 8rem 8rem;padding-bottom: 24rem">
			<div style="height: 57rem;box-sizing: border-box;padding: 0 27rem;display: flex;align-items: center">
				<i class="el-icon-s-comment" style="font-size: 24rem;color: #1C4EFD;margin-right: 15rem"></i>
				<div style="font-size: 20rem;font-weight: 500;color: #1C4EFD;">反馈内容</div>
			</div>
			<div style="width: calc(100% - 20rem);height: 1rem;background: #DBDDE4;margin-left: 10rem"></div>
			<div style="width: calc(100% - 48rem);min-height: 310rem;background: #F9FAFD;border-radius: 8rem 8rem 8rem 8rem;margin-left: 24rem;margin-top: 21rem;box-sizing: border-box;padding: 13rem 15rem">
				<div style="font-size: 14rem;font-weight: normal;color: #222222;">{{ data.content }}</div>
			</div>
			<div style="margin-top: 25rem;margin-left: 24rem;display: flex;">
				<Item :value="data.school_name" label="反馈校区"></Item>
				<Item :value="data.grade_name" label="反馈年级"></Item>
				<Item :value="data.type" label="问题分类"></Item>
				<Item :left="false" :value="data.created_at" label="反馈时间"></Item>
			</div>
		</div>
		<el-button style="background: #326FFF;margin-top: 32rem;color: #FFF" @click="$router.back()">返回</el-button>
	</div>
</template>

<style lang="scss" scoped>

</style>
