<script>
export default {
  name: "Item",
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    left: {
      type: Boolean,
      default: true
    },
  },
}
</script>

<template>
  <div style="display: flex">
    <div style="font-size: 16rem;font-weight: 400;color: #3C3C3C;margin-right: 28rem">{{label}}</div>
    <div style="font-size: 16rem;font-weight: 400;color: #1C4EFD;margin-right: 24rem">{{value}}</div>
    <div v-if="left" style="height: 24rem;width: 1rem;background: #DBDDE4;margin-right: 24rem"></div>
  </div>
</template>

<style scoped lang="scss">

</style>
